<template>
  <BaseLayout :title="title">

    <template #extra>
      <van-button v-show="display('create')" icon="plus" size="small" :style="{borderRadius:'10px 0 0 10px'}" color="#09F" @click="onCreate">新增模板</van-button>
    </template>
    
    <van-popup v-model:show="state.showPicker" round position="bottom">
      <van-picker title="选择策略模板" :columns="state.list" :columns-field-names="{text:'name'}" @cancel="state.showPicker = false" @confirm="onConfirm" />
    </van-popup>

    <van-form class="form" @submit="onSubmit">

      <van-cell class="strategy" :class="state.list.length > 0 ? 'animate__animated animate__repeat-2 animate__flash' : ''" :border="false" :title="name" :label="'共有 ' + state.list.length + ' 个策略模板'" is-link @click="state.showPicker = true" v-show="display('choose')" />
      
      <div class="fields">

        <van-field v-model="state.strategy.name"   name="name"   label="策略名称" placeholder="策略模板的标题" v-show="display('name')" :rules="[{ required: display('name'), message: '必填项' }]"/>
        <van-field v-model="state.strategy.symbol" name="symbol" label="交易对"   placeholder="只支持USDT交易对" clearable v-show="display('symbol')" :rules="[{ required: display('symbol'), message: '必填项' }]" :readonly="action == 'adjust'" :formatter="formatter" />
        <van-field v-model="state.strategy.amount" name="amount" label="建仓金额" placeholder="最少 10 USDT" v-show="display('amount')" :rules="[{ required: display('amount'), message: '必填项' }]"  type="number" min="10" right-icon="warning-o" />
        <van-field name="rise_limit" label="止盈率" right-icon="warning-o">
          <template #input><van-stepper class="stepper" v-model="state.strategy.rise_limit" step="0.1" min="0.1" max="100" />%</template>
        </van-field>
        <van-field name="rise_offset" label="止盈回调比例" right-icon="warning-o">
          <template #input><van-stepper class="stepper" v-model="state.strategy.rise_offset" step="0.1" min="0" max="100"/>%</template>
        </van-field>
        <van-field name="fall_limit" label="补仓下跌率" right-icon="warning-o" >
          <template #input><van-stepper class="stepper" v-model="state.strategy.fall_limit" step="0.1" min="0.1" max="100"/>%</template>
        </van-field>
        <van-field name="fall_offset" label="补仓回调比例" right-icon="warning-o" >
          <template #input><van-stepper class="stepper" v-model="state.strategy.fall_offset" step="0.1" min="0" max="100" />%</template>
        </van-field>
        <van-field name="fall_count" label="补仓次数" right-icon="warning-o">
          <template #input><van-stepper class="stepper" v-model="state.strategy.fall_count" step="1" min="0" max="10" />次</template>
        </van-field>
        <van-field name="fall_series" label="补仓金额" right-icon="warning-o">
          <template #input>
            <van-radio-group v-model="state.strategy.fall_series">
              <van-radio class="radio" :name="0">不补仓</van-radio>
              <van-radio class="radio" :name="1">单倍建仓金额</van-radio>
              <van-radio class="radio" :name="2">单倍递增：2、3、4、5...</van-radio>
              <van-radio class="radio" :name="3">双倍递增：2、4、6、8...</van-radio>
              <van-radio class="radio" :name="4">乘方递增：2、4、8、16...</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field name="stop_limit" label="下跌止损比例" right-icon="warning-o">
          <template #input><van-stepper class="stepper" v-model="state.strategy.stop_limit" step="0.1" min="0.1" max="100" />%</template>
        </van-field>
        <van-field name="stop_action" label="止损动作" right-icon="warning-o">
          <template #input>
            <van-radio-group v-model="state.strategy.stop_action">
              <van-radio class="radio" :name="0">挂起不再补仓</van-radio>
              <van-radio class="radio" :name="1">清仓</van-radio>
            </van-radio-group>
          </template>
        </van-field>
      
      </div>

      <van-sticky class="submit" :offset-bottom="0" position="bottom">
        <van-button block type="primary" native-type="submit">{{title}}</van-button>
      </van-sticky>
      
    </van-form>

  </BaseLayout>
</template>

<style scoped>
.form {
  margin: 10px;
  border-radius: 5px;
}
.strategy {
  background: #F6F6F6;
  border-radius: 5px;
  border: solid 1px #DDD;
  color: #000;
}
.fields {
  margin: 10px 0;
  border-radius: 5px;
  border: solid 1px #DDD;
  overflow: hidden;
}
.stepper {
  margin-right: 10px;
}
.radio {
  height: 32px;
  font-size: 12px;
}
.submit {
  margin-top: 10px;
}
</style>

<script>
import {reactive} from 'vue'
import {mapGetters} from 'vuex'
import BaseLayout from '@/components/BaseLayout.vue'

export default {
  components: {BaseLayout},

  props: {
    action: {
      type: String,
      default: "create",
    },
    id: {
      type: Number,
      default: 0,
    },
  },

  setup(){
    const state = reactive({
      showPicker: false,
      symbols: [],
      list: [],     // 所有策略
      strategy: {}, // 当前策略
      default: {    // 默认策略
        rise_limit: 3,
        rise_offset: 0.3,
        fall_limit: 4,
        fall_offset: 0.4,
        fall_count: 5,
        fall_series: 2,
        stop_limit: 30,
        stop_action: 0,
      }, 
      sid: 0,       // 策略模板ID
    });

    return {
      state,
    };
  },

  created() {
    this.state.strategy = this.state.default;
    this.$http.get("/exchange/symbol").then((data) => this.state.symbols = data);
    this.$http.post("/exchange/strategylist").then((data) => {
      if (this.action == "modify" && data.length == 0){
        this.$toast("还没有策略模板，请先增加");
        return this.$router.push("/my/strategy/create");
      }
      this.state.list = data;
    });
  },

  mounted(){
    this.id > 0 && this.$http.post("/exchange/taskdetail?id=" + this.id).then((data) => {
      this.state.strategy = this.change(data["strategy"], 100);
    });
  },

  computed: {

    title() {
      let obj = {
        create: "新增策略模板",
        modify: "修改策略模板",
        adjust: "调整策略 #" + this.id,
        apply: "手动建仓",
      }
      return typeof obj[this.action] != "undefined" ? obj[this.action] : obj.create;
    },

    name(){
      return this.action == "modify" ? "请先点击选择要修改的策略模板" : "可点击选择策略模板，填充到下面表单中";
    },

    ...mapGetters(["uid"]),
  },

  methods: {

    display(type){
      if (type == "create"){
        return this.action == "modify" ? true : false;
      }
      if (type == "choose"){
        return (this.action == "modify" || this.action == "apply") ? true : false;
      }
      if (type == "name"){
        return (this.action == "create" || this.action == "modify") ? true : false;
      }
      if (type == "symbol"){
        return (this.action == "adjust" || this.action == "apply") ? true : false;
      }      
      if (type == "amount"){
        return (this.action != "adjust") ? true : false;
      }
      return false;
    },

    change(obj, multiple) {
      return {...obj, ...{
        rise_limit : obj.rise_limit * multiple,
        rise_offset: obj.rise_offset * multiple,
        fall_limit : obj.fall_limit * multiple,
        fall_offset: obj.fall_offset * multiple,
        fall_series: parseInt(obj.fall_series),
        stop_limit : obj.stop_limit * multiple,
        stop_action: parseInt(obj.stop_action)
      }};
    },

    formatter(value){
      let symbol = value.toUpperCase().replace(/[^\w]/g, '');
      if (this.state.symbols.join("|").toUpperCase().indexOf(symbol) == -1)
        return symbol.substring(0, symbol.length-1);
      return symbol;
    },

    onCreate(){
      this.state.strategy = this.state.default;
      this.$router.push('/my/strategy/create');
    },

    onConfirm(obj) {
      this.state.showPicker = false;
      this.state.sid = obj.id;
      this.state.strategy = this.change(obj, 100);
      this.$copy(obj.id, false);
    },

    onSubmit(obj){
      let type = "create,modify".indexOf(this.action) > -1 ? "strategy" : "task";
      let url = "/exchange/" + type + "save";
      let params = this.change(obj, 1/100);

      if (this.action == "modify"){
        if (this.state.sid == 0)
          return this.$toast("请先选择要修改的策略模板");
        params["id"] = this.state.sid;
      }

      if (this.action == "adjust"){
        if (this.id == 0)
          return this.$toast("缺少任务ID");
        params["id"] = this.id;
      }
      
      type == "task" && this.uid == 1 && (params["test"] = 1);

      this.$http.post(url, params).then((data) => {
        this.$router.push("/my/exchange");
      });
    }
  }
}
</script>
